// const BASE_GOOGLE_DOCS_URL = 'https://drive.google.com';

export const googleSlidesFormat = new RegExp('https://docs.google.com/presentation/d/(.+?)/', 'i');
export const googleDocsFormat = new RegExp('https://drive.google.com/file/d/(.+?)/', 'i');

export const formatForDownloadGoogleDriveURL = (url) => {
  // https://drive.google.com/file/d/1VhHUq0kjoWRD9gKlvu059TEzMKXvLgp-/view
  try {
    if (url.endsWith('/view')) {
      return url;
    }
    if (url.endsWith('/')) {
      return `${url}view`;
    }
    if (url.endsWith('/preview')) {
      return url.replace('/preview', '/view');
    }
  } catch (e) {
    console.error('URL not recognised as google docs url', url, e);
  }
  return url;
};

export const formatForPreviewGoogleDriveURL = (url) => {
  // https://drive.google.com/file/d/1VhHUq0kjoWRD9gKlvu059TEzMKXvLgp-/view
  try {
    if (url.endsWith('/preview')) {
      return url;
    }
    if (url.endsWith('/')) {
      return `${url}preview`;
    }
    if (url.endsWith('/view')) {
      return url.replace('/view', '/preview');
    }
  } catch (e) {
    console.error('URL not recognised as google docs url', url, e);
  }
  return url;
};
